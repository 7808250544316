import React, { useEffect, useState } from "react";
import styles from "./MainReserve.module.css";
import "../../assets/general-styles/styles.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { Button, Container, TextField } from "@mui/material";
import Zal from "./zal/Zal";
import Veranda from "./veranda/Veranda";
import Regsuccessfully from "./regsuccessfully/Regsuccessfully";
import dayjs from "dayjs";
import { reserveApi } from '../../api/reserveApi';

const MainReserve = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [showTableNumber, setShowTableNumber] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));
  // const [successCard, setSuccessCard] = useState(false);
  //! предупреждение для поля если она не зопльнено
  const [validationMessage, setValidationMessage] = useState({
    name: "",
    phone: "",
    date: "",
    time: "",
    guestsCount: "",
    // user_email: "",
  });
  const [errors, setErrors] = useState({});

  //! Молальное окно прошло успешно
  const [sucessCardReserveTable, setSucessCardReserveTable] = useState(false);

  const handlerReserveTable = () => {

    let errorMessages = {};
    const maxGuests = place === "Зал" ? 26 : 50;

    if (!place.trim()) {
      errorMessages = { ...errorMessages, place: "обязательное поле" };
    }
    if (!name.trim()) {
      errorMessages = { ...errorMessages, name: "обязательное поле" };
    }
    // if (!user_email.trim()) {
    //   errorMessages = { ...errorMessages, user_email: "обязательное поле" };
    // }
    if (!phone.trim()) {
      errorMessages = { ...errorMessages, phone: "обязательное поле" };
    }
    if (!date) {
      errorMessages = { ...errorMessages, date: "обязательное поле" };
    }
    if (!time) {
      errorMessages = { ...errorMessages, time: "Пожалуйста, выберите время." };
    } else {
      // Проверка времени только если оно не null
      const [hours, minutes] = time.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes;

      if (totalMinutes >= 22 * 60 || totalMinutes < 11 * 60) {
        errorMessages = {
          ...errorMessages,
          time: "Заведение не работает в это время.",
        };
      }
    }

    if (Number(guestsCount) <= 0 || Number(guestsCount) > maxGuests) {
      errorMessages = {
        ...errorMessages,
        guestsCount: `Введите корректное число гостей (не более ${maxGuests})`,
      };
    }
    return errorMessages;
  };

  const [date, setDate] = useState("open");
  const [time, setTime] = useState("");
  const [guestsCount, setGuestsCount] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [optional, setOptional] = useState("");
  const [place, setPlace] = useState("");
  const [placeLimit, setPlaceLimit] = useState("");
  const [hall, setHall] = useState("");
  const [successCard, setSuccessCard] = useState(false);
  // const [user_email, setEmailUser] = useState("");


  const handler = {
    name: (e) => {
      setName(e.target.value);
    },
    // user_email: (e) => {
    //   setEmailUser(e.target.value);
    // },
    phone: (e) => {
      setPhone(e.target.value);
    },
    optional: (e) => {
      setOptional(e.target.value);
    },
    time: (timeValue) => {
      if (timeValue && timeValue.isValid()) {
        const formattedTime = timeValue.format('HH:mm'); // Форматируем время правильно
        setTime(formattedTime);

        // Проверка, находится ли время в диапазоне с 23:00 до 11:00
        const [hours, minutes] = formattedTime.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;

        if (totalMinutes >= 22 * 60 || totalMinutes < 11 * 60) {
          setErrors({ time: "Заведение не работает в это время." });
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, time: undefined })); // Сбрасываем ошибку, если время допустимо
        }
      } else {
        setErrors({ time: "Некорректное время." }); // Обработка некорректного ввода времени
      }
    },

    date: (e) => {
      if (e && e["$d"]) {
        setDate(`${e["$d"]}`.slice(0, 15));
        console.log(`${e["$d"]}`.slice(4, 15));
      } else {
      }
    },


    guestsCount: (e) => {
      const enteredGuestsCount = Number(e.target.value);
      const maxGuests = placeLimit === "Зал" ? 26 : 50;

      if (enteredGuestsCount > maxGuests) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          guestsCount: `Максимальное число гостей составляет ${maxGuests}`,
        }));
        setGuestsCount(maxGuests);
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, guestsCount: undefined }));
        setGuestsCount(enteredGuestsCount);
      }
    },
    closeModal: () => {
      setIsModalOpen(false);
      // Set showTableNumber based on the current place
      setShowTableNumber(place === "Зал" || place === "Веранда");
    },

    place: (e) => {
      const selectedOption = e.target.value;
      setPlace(selectedOption);
      setPlaceLimit(selectedOption);
      const maxGuests = placeLimit === "Зал" ? 26 : 50;

      setGuestsCount((prevCount) => Math.min(prevCount, maxGuests));

      setShowTableNumber(
        e.target.value === "Зал" || e.target.value === "Веранда"
      );
      if (e.target.value === closeModal) {
        return;
      }

      if (e.target.value === "Зал") {
        setHall("hall");
        setIsModalOpen(true);
      } else if (e.target.value === "Веранда") {
        setHall("veranda");

        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    },
    closeModal: () => {
      // Сбросить выбор места при закрытии модального окна
      setShowTableNumber() // Или setPlace("Выбрать"), если хотите установить значение по умолчанию
      setIsModalOpen(false);
    },
    placeZal: (num) => {
      setPlace(num);
    },
    placeVeranda: (num) => {
      setPlace(num);
    },
    submit: () => {
      const errorMessages = handlerReserveTable();
      // Проверка авторизации
      if (!isAuthenticated) {
        alert("Пожалуйста, зарегистрируйтесь для бронирования."); // Уведомление о необходимости авторизации
        return; // Прерываем выполнение
      }

      // Если есть ошибки, прерываем выполнение
      if (Object.keys(errorMessages).length > 0) {
        setErrors(errorMessages);
        return; // Выходим из функции, если есть ошибки
      }
      const data = {
        room_type: hall,
        table_number: place,
        date_time: `${date} ${time}`,
        num_people: guestsCount,
        name: name,
        phone: phone,
        comment: optional,
        // user_email: user_email,
      };

      const token = localStorage.getItem("token");
      // console.log(token, 'token', data, 'data')
      reserveApi.reserveTable(token, data)
        .then((data) => {
          setSuccessCard(true);
          setSucessCardReserveTable(true);
        })
        .then(() => {
          setTimeout(() => {
            setSucessCardReserveTable(false);
            setValidationMessage({
              name: "",
              phone: "",
              date: "",
              time: "",
              guestsCount: "",
              // user_email: ""
            });
            // window.location.reload();
          }, 3000);
        })
        .catch((err) => console.log(err));
      // handlerReserveTable();
    },
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Очистка эффекта при размонтировании компонента
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen]);

  return (
    <>
      <div>
        <div id="/home#table-reserve" className={styles.backgroundBodyReserve}>
          <h3 className="title__pages_all">Резерв стола</h3>

          <Container className={styles.ContainerCenter}>
            <div className="container__reserve reserve">
              {/* Зал__и__веранда */}

              <div className="reserve__title__subtitle">
                <div className={styles.SelectReserveSelectRow}>
                  <div className="reserve__subtitle__left">Зал / Веранда *</div>
                  <div className={styles.InputRowError}>
                    <div>
                      <select
                        aria-invalid="false"
                        className={styles.ReserveOption}
                        onChange={handler.place}
                        onClick={handler.place}
                      >
                        <option hidden disabled selected>
                          Выбрать
                        </option>
                        <option value="Зал">Зал</option>
                        <option value="Веранда">Веранда</option>
                      </select>
                      {showTableNumber && (
                        <span className={styles.spanReserve}>
                          {" Стол №:"} {place.indexOf("Table") && `${place}`}
                        </span>
                      )}
                    </div>
                    {errors.place && (
                      <div style={{ color: "red" }}>{errors.place}</div>
                    )}
                  </div>
                </div>
              </div>
              {isModalOpen ? (
                place === "Зал" ? (
                  <Zal closeModal={closeModal} handler={handler.placeZal} />
                ) : (
                  <Veranda
                    closeModal={closeModal}
                    handler={handler.placeVeranda}
                  />
                )
              ) : null}

              {/* Дата__и__время */}
              <div className="reserve__title__subtitle">
                <div className="reserve__subtitle__left">Дата / Время *</div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <div className={styles.InputRowError}>
                      <DatePicker
                        // !Убрать border у data and time
                        disablePast
                        slotProps={{ textField: { variant: "standard" } }}
                        sx={{
                          width: "100%",
                          svg: { color: "#fff" },
                          input: { color: "#fff", height: "63px", fontSize: "30px" },
                          label: {
                            color: "#fff",
                            fontSize:
                              "calc(20px + 12 * ((100vw - 320px) / (1920 - 320)))",
                            fontFamily: "Lato",
                            marginLeft: "6px",
                            // height: "73px !important",
                          },
                        }}
                        label="Выбрать дату"
                        variant="standard"
                        onChange={handler.date}
                      />
                      {/* <DemoItem label="Mobile variant">
                        <MobileDatePicker />
                      </DemoItem> */}

                      {errors.date && (
                        <div style={{ color: "red" }}>{errors.date}</div>
                      )}
                    </div>

                    <div className={styles.InputRowError}>
                      <TimePicker
                        // !Убрать border у data and time
                        slotProps={{ textField: { variant: "standard" } }}
                        sx={{
                          width: "100%",
                          svg: { color: "#fff" },
                          input: { color: "#fff", height: "63px", fontSize: "30px" },
                          label: {
                            color: "#fff",
                            fontSize:
                              "calc(25px + 12 * ((100vw - 320px) / (1920 - 320)))",
                            fontFamily: "Lato",
                            marginLeft: "6px",
                            // height: "73px !important",
                          },
                          fontSize: "40px",
                        }}
                        label="Выбрать время"
                        role="option"
                        ampm={false}
                        value={time ? dayjs(time) : null}
                        // onChange={handler.time}
                        onChange={handler.time}

                      />
                      {
                        errors.time && (
                          <div style={{ color: "red" }}>{errors.time}</div>
                        )
                      }
                    </div>
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              {/* Имя *  */}
              <div className="reserve__title__subtitle">
                <div className="reserve__subtitle__left">Имя *</div>
                <div className={styles.InputRowError}>
                  <TextField
                    InputProps={{
                      sx: {
                        "& input": {
                          color: "#fff",
                          width: "500px",
                          fontFamily: "Lato",
                          fontSize: "30px",
                          height: "73px",
                        },
                      },
                    }}
                    required
                    margin="dense"
                    id="date"
                    placeholder="Ваше имя"
                    color="black"
                    variant="standard"
                    value={name}
                    onChange={handler.name}
                  />
                  {errors.name && (
                    <div style={{ color: "red" }}>{errors.name}</div>
                  )}
                </div>
              </div>

              {/* <div className="reserve__title__subtitle">
                <div className="reserve__subtitle__left">Почта *</div>
                <div className={styles.InputRowError}>
                  <TextField
                    InputProps={{
                      sx: {
                        "& input": {
                          color: "#fff",
                          width: "500px",
                          fontFamily: "Lato",
                          fontSize: "30px",
                          height: "73px",
                        },
                      },
                    }}
                    required
                    margin="dense"
                    id="date"
                    placeholder="Ваш почта"
                    color="black"
                    variant="standard"
                    value={user_email}
                    onChange={handler.user_email}
                  />
                  {errors.user_email && (
                    <div style={{ color: "red" }}>{errors.user_email}</div>
                  )}
                </div>
              </div> */}

              {/* Телефон *  */}
              <div className="reserve__title__subtitle">
                <div className="reserve__subtitle__left">Телефон *</div>
                <div className={styles.InputRowError}>
                  <TextField
                    InputProps={{
                      sx: {
                        "& input": {
                          color: "#fff",
                          width: "479px",
                          fontFamily: "Lato",
                          fontSize: "30px",
                          height: "73px",
                        },
                      },
                    }}
                    required
                    // autoFocus
                    margin="dense"
                    id="date"
                    placeholder="Ваш телефон"
                    type="number"
                    color="black"
                    variant="standard"
                    value={phone}
                    onChange={handler.phone}
                  />
                  {errors.phone && (
                    <div style={{ color: "red" }}>{errors.phone}</div>
                  )}
                </div>
              </div>

              {/* Число гостей   */}
              <div className="reserve__title__subtitle">
                <div className={styles.SelectReserveSelectRow}>
                  <div className="reserve__subtitle__left">Число гостей *</div>
                  <div className={styles.InputRowError}>
                    <input
                      className={styles.ReserveOption}
                      onChange={handler.guestsCount}
                      type="number"
                      placeholder="Число гостей"
                      required
                      max={placeLimit === "Зал" ? 26 : 50}
                    />

                    {errors.guestsCount && (
                      <div style={{ color: "red" }}>{errors.guestsCount}</div>
                    )}
                  </div>
                </div>
              </div>

              {/* По желанию  */}
              <div className="reserve__title__subtitle">
                <div className="reserve__subtitle__left">Комментарий</div>
                <TextField
                  InputProps={{
                    sx: {
                      "& input": {
                        color: "#fff",
                        minWidth: "100% !important",
                        fontFamily: "Lato",
                        fontSize: "30px",
                        height: "73px",
                      },
                    },
                  }}
                  required
                  margin="dense"
                  id="date"
                  placeholder="По желанию"
                  color="black"
                  variant="standard"
                  value={optional}
                  onChange={handler.optional}
                />
              </div>

              {/* Button__Bron */}
              <div className="reserve__buttton">
                <span id="/home#delivery"></span>
                {sucessCardReserveTable ? (
                  <Regsuccessfully />
                ) : (
                  <button
                    className="reserve__order__button"
                    style={{
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      handler.submit();
                    }}
                  >
                    Забронировать
                  </button>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default MainReserve;
