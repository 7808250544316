import styless from './basketButton.module.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/general-styles/styles.css";




const BasketButton = ({ elem, basketItems = [], setBasketItems }) => {
    const [add, setAdd] = useState(false);
    const token = localStorage.getItem("token");
    const isTokenValid = token && token.length > 5;
    const currentItem = basketItems.find(item => item.goods.id === elem.id);
    const currentCount = currentItem ? currentItem.count : 0;

    useEffect(() => {
        const updateCartOnServer = async () => {
            if (currentCount > 0) {
                try {
                    await axios.patch(
                        `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
                        { count: currentCount },
                        {
                            headers: {
                                authorization: `Token ${token}`,
                            },
                        }
                    );
                } catch (err) {
                    console.log(err);
                }
            }
        };

        updateCartOnServer();
    }, [currentCount, elem.id, token]);

    const addToCart = async () => {
        if (!isTokenValid) {
            alert("Пожалуйста зарегистрируйтесь!!");
            return; // Прерываем выполнение функции
        }
        const isInBasket = basketItems.some(item => item.goods.id === elem.id);
        try {
            await axios.post(
                `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
                { count: isInBasket ? currentCount + 1 : 1 },
                {
                    headers: {
                        authorization: `Token ${token}`,
                    },
                }
            );
            setBasketItems(prev => {
                const existingItem = prev.find(item => item.goods.id === elem.id);

                if (existingItem) {
                    // Если товар уже есть в корзине, увеличиваем его количество
                    return prev.map(item =>
                        item.goods.id === elem.id ? { ...item, count: item.count + 1 } : item
                    );
                } else {
                    // Если товара нет в корзине, добавляем его с количеством 1
                    return [...prev, { goods: { ...elem, ...elem.all }, count: 1 }];
                }
            });
            // setAdd(true)
        } catch (err) {
            console.log(err);
        }
    };

    const decreaseItemCount = async () => {
        if (currentCount > 1) {
            // Уменьшаем количество товара в состоянии корзины
            setBasketItems(prev => prev.map(item =>
                item.goods.id === elem.id ? { ...item, count: item.count - 1 } : item
            ));

            // Обновляем базу данных, чтобы отразить уменьшение
            try {
                await axios.patch(
                    `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
                    { count: -1 }, // Уменьшаем количество на 1
                    {
                        headers: {
                            authorization: `Token ${token}`,
                        },
                    }
                );
            } catch (err) {
                console.log(err);
            }
        } else {
            // Удаляем товар из состояния корзины и базы данных
            setBasketItems(prev => prev.filter(item => item.goods.id !== elem.id));

            // Отправляем DELETE-запрос для удаления товара из базы данных
            try {
                await axios.delete(
                    `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
                    {
                        headers: {
                            authorization: `Token ${token}`,
                        },
                    }
                );
            } catch (err) {
                console.log(err);
            }
        }
    };

    const increaseItemCount = async () => {
        setBasketItems(prev => prev.map(item =>
            item.goods.id === elem.id ? { ...item, count: item.count + 1 } : item
        ));
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // предотвращает обновление страницы
    };

    return (
        <form className={styless.formBtn} onSubmit={handleSubmit}>
            {
                currentCount ? (
                    <div className={styless.countBtn} >
                        <button type="button" className={styless.countDecrease} onClick={() => decreaseItemCount()}>-</button>
                        <span className={styless.countBasket}>{currentCount}</span>
                        <button type="button" className={styless.countIncrease} onClick={() => increaseItemCount()}>+</button>
                    </div >
                ) : (
                    <button
                        type="button"
                        className="btn"
                        onClick={() =>
                            isTokenValid
                                ? addToCart()
                                : alert("Пожалуйста зарегистрируйтесь!!")
                        }
                    >
                        + В корзину
                    </button>
                )}
        </form>

    )
}

export default BasketButton;