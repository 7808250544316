import axios from "axios";

export const addetionalServiseApi = async (token, data) => {
  try {
    const res = await axios.request({
      url: "https://dali-khinkali.ru/api/send-order/",
      data: data,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    return await res;
  } catch (error) {
    return error.message;
  }
};
// export const addetionalServiseApi = async (token, data, goodsToSend, finalPrice, goodDisc) => {
//   try {
//     // Step 1: Send the order
//     const orderResponse = await axios.request({
//       url: "https://dali-khinkali.ru/api/send-order/",
//       data: {
//         decription: data.description,
//         goods_id: goodsToSend,
//         count_goods: data.count_goods,
//         price_goods: data.price_goods,
//         address: data.address,
//         delivery_time: data.delivery_time,
//         final_price: finalPrice,
//       },
//       headers: {
//         Authorization: `Token ${token}`,
//         "Content-Type": "application/json",
//       },
//       method: "POST",
//     });

//     // Step 2: Process the payment
//     const paymentResponse = await axios.request({
//       url: "https://dali-khinkali.ru/api/payment/",
//       method: "POST",
//       data: {
//         service_name: goodDisc,
//         num_order: goodsToSend,
//         price: finalPrice,
//       },
//       headers: {
//         Authorization: `Token ${token}`,
//         "Content-Type": "application/json",
//       },
//     });

//     // Step 3: Handle redirection if payment is successful
//     const redirectUrl = paymentResponse.data.success;
//     if (redirectUrl) {
//       window.location.href = redirectUrl;
//     }

//     return { orderResponse, paymentResponse };
//   } catch (error) {
//     // Handle errors appropriately
//     return error.message;
//   }
// };
const url = "https://dali-khinkali.ru/api";
export const reserveApi = {
  hookah: async (token, data) => {
    const headers =
      `${token}`.length > 5
        ? {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        }
        : {
          "Content-Type": "application/json",
        };
    const res = await axios.request({
      url: `${url}/send-hookah/`,
      method: "POST",
      headers: headers,
      data: data,
    });
    return await res.data;
  },

  reserveTable: async (token, data) => {
    const headers =
      `${token}`.length > 5
        ? {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        }
        : {
          "Content-Type": "application/json",
        };
    const res = await axios.request({
      url: `${url}/reservations/`,
      method: "POST",
      headers: headers,
      data: data,
    });
    return await res.data;
  },
  taxi: async (token, data) => {
    const headers =
      `${token}`.length > 5
        ? {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        }
        : {
          "Content-Type": "application/json",
        };
    const res = await axios.request({
      url: `${url}/send-taxi/`,
      method: "POST",
      headers: headers,
      data: data,
    });
    return await res.data;
  },
};
