import React, { useEffect, useState } from "react";
import styles from "./MainHall.module.css";
import "../../assets/general-styles/styles.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import reserveBackgroundRight from "../../assets/images/menu__hull.png";
import { Button, Container, TextField } from "@mui/material";
import Regsuccessfully from "./regsuccessfully/Regsuccessfully";
import { banquetHullApi } from "../../api/banquetApi";
import dayjs from "dayjs";

const MainReserve = () => {
  const [successCard, setSuccessCard] = useState(false);
  //! предупреждение для поля если она не зопльнено
  const [validationMessage, setValidationMessage] = useState({
    name: "",
    phone: "",
    date: "",
    time: "",
    guestsCount: ""
  });
  //! Молальное окно прошло успешно
  const [sucessCardReserveTable, setSucessCardReserveTable] = useState(false);
  const [email_user, setEmailUser] = useState("");
  const [errors, setErrors] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));

  const handlerReserveTable = () => {
    let errorMessages = {};
    if (!name.trim()) {
      errorMessages = { ...errorMessages, name: "обязательное поле" };
    }
    if (!phone.trim()) {
      errorMessages = { ...errorMessages, phone: "обязательное поле" };
    }
    if (!date) {
      errorMessages = { ...errorMessages, date: "обязательное поле" };
    }
    if (!time) {
      errorMessages = { ...errorMessages, time: "Пожалуйста, выберите время." };
    } else {
      // Проверка времени только если оно не null
      const [hours, minutes] = time.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes;

      if (totalMinutes >= 22 * 60 || totalMinutes < 11 * 60) {
        errorMessages = {
          ...errorMessages,
          time: "Заведение не работает в это время.",
        };
      }
    }

    return errorMessages;
  };

  //! ///////////////////////////////////

  //! ///////////////////////////////////
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [guestsCount, setGuestsCount] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [optional, setOptional] = useState("");

  const handler = {
    name: (e) => {
      setName(e.target.value);
    },
    phone: (e) => {
      setPhone(e.target.value);
    },
    optional: (e) => {
      setOptional(e.target.value);
    },
    email_user: (e) => {
      setEmailUser(e.target.value)
    },
    time: (timeValue) => {
      if (timeValue && timeValue.isValid()) {
        const formattedTime = timeValue.format('HH:mm'); // Форматируем время правильно
        setTime(formattedTime);

        // Проверка, находится ли время в диапазоне с 23:00 до 11:00
        const [hours, minutes] = formattedTime.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;

        if (totalMinutes >= 22 * 60 || totalMinutes < 11 * 60) {
          setErrors({ time: "Заведение не работает в это время." });
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, time: undefined })); // Сбрасываем ошибку, если время допустимо
        }
      } else {
        setErrors({ time: "Некорректное время." }); // Обработка некорректного ввода времени
      }
    },

    date: (e) => {
      setDate(`${e["$d"]
        }`.slice(0, 15));
      console.log(`${e["$d"]} `.slice(4, 15));
    },
    guestsCount: (e) => {
      setGuestsCount(e.target.value);
      console.log(guestsCount);
    },

  };

  const BanquetHallPreOrder = (e) => {
    e.preventDefault();
    const errorMessages = handlerReserveTable();
    if (!isAuthenticated) {
      alert("Пожалуйста, зарегистрируйтесь для бронирования"); // Уведомление о необходимости авторизации
      return; // Прерываем выполнение
    }

    // Если есть ошибки, прерываем выполнение
    if (Object.keys(errorMessages).length > 0) {
      setErrors(errorMessages);
      return; // Выходим из функции, если есть ошибки
    }

    const token = localStorage.getItem("token");
    const data = {
      date_time: `${date} ${time}`,
      count_people: guestsCount,
      hall: "Зал",
      first_name: name,
      email_user: email_user,
      phone: phone,
      additional_services: optional,
    };
    banquetHullApi(token, data)
      .then((data) => {
        setSuccessCard(true);
        setSucessCardReserveTable(true);
      })
      .then(() => {
        setTimeout(() => {
          setSucessCardReserveTable(false);
          setValidationMessage({
            name: "",
            phone: "",
            date: "",
            time: "",
            guestsCount: "",
            email_user: ""
          });
          // window.location.reload();
        }, 3000);
      })
      .catch((err) => alert(err));

  }

  const guestsCountArr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  return (
    <>
      <div>
        <div className={styles.backgroundBodyReserve} id="/home#hall">
          <h3 className="title__pages_all">Банкетный зал</h3>
          <Container className={styles.containerHall}>
            <div className={styles.rowHull}>
              <div className={`container__reserve ${styles.containerReserve} `}>
                {/* Дата__и__время */}
                <div className="reserve__title__subtitle">
                  <div className="reserve__subtitle__left__hull">
                    Дата / Время *
                  </div>
                  <div className={styles.InputRowError}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <DatePicker
                          // !Убрать border у data and time
                          disablePast
                          slotProps={{ textField: { variant: "standard" } }}
                          sx={{
                            width: "94%",
                            svg: { color: "#fff" },
                            input: { color: "#fff" },
                            label: {
                              color: "#fff",
                              fontSize:
                                "calc(15px + 11 * ((100vw - 320px) / (1920 - 320)));",
                              fontFamily: "Lato",
                              marginLeft: "6px",
                              top: "-16px",
                            },
                          }}
                          label="Выбрать дату"
                          variant="standard"
                          onChange={handler.date}
                        />
                        <TimePicker
                          // !Убрать border у data and time
                          slotProps={{ textField: { variant: "standard" } }}
                          sx={{
                            width: "100%",
                            svg: { color: "#fff" },
                            input: { color: "#fff" },
                            label: {
                              color: "#fff",
                              fontSize:
                                "calc(15px + 11 * ((100vw - 320px) / (1920 - 320)));",
                              fontFamily: "Lato",
                              marginLeft: "6px",
                              top: "-16px",
                            },
                          }}
                          label="Выбрать время"
                          ampm={false}
                          onChange={handler.time}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {validationMessage.date && (
                      <div style={{ color: "red" }}>{validationMessage.date}</div>
                    )}
                    {errors.time && (
                      <div style={{ color: "red" }}>{errors.time}</div>
                    )}

                  </div>
                </div>

                {/* Имя *  */}
                <div className="reserve__title__subtitle">
                  <div className="reserve__subtitle__left__hull">Имя *</div>
                  <div className={styles.InputRowError}>
                    <TextField
                      InputProps={{
                        sx: {
                          "& input": {
                            color: "#fff",
                            width: "300px",
                            fontFamily: "Lato",
                          },
                        },
                      }}
                      // autoFocus
                      margin="dense"
                      id="date"
                      placeholder="Ваше Имя *"
                      color="black"
                      variant="standard"
                      onChange={handler.name}
                    />
                    {/* <TextField
                      label="Ваше Имя"
                      variant="outlined"
                      value={name}
                      onChange={handler.name}
                      // error={!!validationMessage.email}
                      helperText={validationMessage.name}
                    /> */}
                    {validationMessage.name && (
                      <div style={{ color: "red" }}>{validationMessage.name}</div>
                    )}
                  </div>
                </div>

                {/* Почта *  */}
                <div className="reserve__title__subtitle">
                  <div className="reserve__subtitle__left__hull">Почта *</div>
                  <div className={styles.InputRowError}>
                    {/* <TextField
                      label="Электронная почта"
                      variant="outlined"
                      value={email_user}
                      onChange={handler.email_user}
                      // error={!!validationMessage.email}
                      helperText={validationMessage.email_user}
                    /> */}
                    <TextField
                      InputProps={{
                        sx: {
                          "& input": {
                            color: "#fff",
                            width: "300px",
                            fontFamily: "Lato",
                          },
                        },
                      }}
                      margin="dense"
                      id="date"
                      placeholder="Ваша почта *"
                      color="black"
                      variant="standard"
                      onChange={handler.email_user}
                    />
                    {validationMessage.email_user && (
                      <div style={{ color: "red" }}>{validationMessage.email_user}</div>
                    )}
                  </div>
                </div>
                {/* Телефон *  */}
                <div className="reserve__title__subtitle">
                  <div className="reserve__subtitle__left__hull">Телефон *</div>
                  <div className={styles.InputRowError}>
                    <TextField
                      InputProps={{
                        sx: {
                          "& input": {
                            color: "#fff",
                            width: "300px",
                            fontFamily: "Lato",
                          },
                        },
                      }}
                      margin="dense"
                      id="date"
                      placeholder="Ваш Телефон *"
                      color="black"
                      variant="standard"
                      onChange={handler.phone}
                    />
                    {/* <TextField
                      label="Телефон"
                      variant="outlined"
                      value={phone}
                      onChange={handler.phone}
                      // error={!!validationMessage.email}
                      helperText={validationMessage.phone}
                    /> */}
                    {validationMessage.phone && (
                      <div style={{ color: "red" }}>{validationMessage.phone}</div>
                    )}
                  </div>
                </div>

                {/* Чисо гостей   */}
                <div className="reserve__title__subtitle">
                  <div className={styles.SelectReserveSelectRow}>
                    <div className="reserve__subtitle__left__hull">
                      Число гостей *
                    </div>
                    <div className={styles.InputRowError}>
                      <select
                        aria-invalid="false"
                        required
                        className={styles.ReserveOption}
                        onChange={handler.guestsCount}
                      >
                        <option hidden disabled selected>
                          1 - 30
                        </option>
                        {guestsCountArr.map((el) => {
                          return <option value={el}>{`${el} `}</option>;
                        })}
                      </select>
                      {validationMessage.guestsCount && (
                        <div style={{ color: "red" }}>{validationMessage.guestsCount}</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* По желанию  */}
                <div className="reserve__title__subtitle">
                  <div className="reserve__subtitle__left__hull">
                    Комментарий
                  </div>
                  <TextField
                    InputProps={{
                      sx: {
                        "& input": {
                          color: "#fff",
                          width: "300px",
                          fontFamily: "Lato",
                        },
                      },
                    }}
                    // autoFocus
                    margin="dense"
                    id="date"
                    placeholder="По желанию"
                    color="black"
                    variant="standard"
                    onChange={handler.optional}
                  />

                  {/* <TextField
                    label="Комментарии"
                    variant="outlined"
                    value={optional}
                    onChange={handler.optional}
                    // error={!!validationMessage.email}
                    helperText={validationMessage.optional}
                  /> */}
                </div>

                {/* Button__Bron */}

                <div className="reserve__buttton">
                  <span id="/home#kalyan"></span>
                  {sucessCardReserveTable ? (
                    <Regsuccessfully />
                  ) : (
                    <button
                      className="reserve__order__button"
                      style={{
                        borderRadius: "20px",
                      }}
                      onClick={BanquetHallPreOrder}
                    >
                      Предзаказ
                    </button>
                  )}
                </div>
              </div>

              {/* Hull__Background__Right */}
              <div className={styles.backgroundHull}>
                <img src={reserveBackgroundRight} alt="Images" />
              </div>
            </div>
          </Container>
        </div >
      </div >
    </>
  );
};

export default MainReserve;
