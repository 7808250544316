import React from "react";
import styles from "./DishesOnTheGrill.module.css";
import "../../../assets/general-styles/styles.css";
import BasketButton from "../../../components/basketButton/basketButton";

const Card = ({ elem, basketItems, setBasketItems }) => {

  return (
    <div className="CardContainer">
      <div className={styles.cardSalat} key={elem.id}>
        <div className="ImgField imgBeverage">
          <img className="image hoverBeverage" src={elem.src} alt={elem.title} />
        </div>
        <div className="cardTitle">{elem.title}</div>
        <div className={styles.BoxWeightSubTitle}>
          <div className="title">{elem.subtitle}</div>
          <span className="weight">{elem.weight}г</span>
        </div>
        <div className="btns">
          <p className="price">{elem.price} руб.</p>
          <BasketButton
            elem={elem}
            basketItems={basketItems}
            setBasketItems={setBasketItems}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
