import React, { useEffect, useState, useRef} from "react";
import styles from "./HeaderModal.module.css";
import { NavLink } from "react-router-dom";
import Close from "../../../assets/img/X_close.png";
import BackIcon from "../../../assets/img/iconBack.png";
import Salat from "../../../pages/menu/salat/Salat";
import Beverage from "../../../pages/menu/beverages/Beverages";
import Snacks from "../../../pages/menu/snacks/Snacks";
import Soups from "../../../pages/menu/soups/Soups";
import Garnish from "../../../pages/menu/garnish/Garnish";
import HotDishes from "../../../pages/menu/hotDishes/HotDishes";
import DishesOnTheGrill from "../../../pages/menu/dishesOnTheGrill/DishesOnTheGrill";
import Sauces from "../../../pages/menu/sauces/Sauces";
import Bread from "../../../pages/menu/bread/Bread";
import Dessert from "../../../pages/menu/dessert/Dessert";
import Bakery from "../../../pages/menu/bakery/Bakery";
import BasketButton from "../../basketButton/basketButton";

const Pages = [
  { name: "Салаты", url: "" },
  { name: "Закуски", url: "" },
  { name: "Супы", url: "" },
  { name: "Гарниры", url: "" },
  { name: "Выпечка", url: "" },
  { name: "Горячие блюда", url: "" },
  { name: "Блюда на мангале", url: "" },
  { name: "Соусы", url: "" },
  { name: "Хлеб", url: "" },
  { name: "Десерты", url: "" },
  { name: "Напитки", url: "" },
];
function HeaderModal({
  pages,
  handleCklick,
  closeModal,
  handleClickScroll,
  basketItems,
  setBasketItems,
}) {
  const [menuDrop, setMenuDrop] = useState(false);

  const menuDropControl = () => {
    setMenuDrop((prev) => !prev);
  };
  const backFromMenu = () => {
    setMenuDrop(false);
  };
  const modalRef = useRef(null); // Создаем ref для модального окна

  // Обработчик кликов вне модального окна
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCklick(); // Закрываем модальное окно
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  console.log(backFromMenu)
  const [cardProducts, setCardProducts] = useState("Салаты");

  const renderSwitch = (param) => {
    const itemProps = { basketItems, setBasketItems };
    switch (param) {
      case "Салаты":
        return (
          // <Salat basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
            <Salat {...itemProps} />
            <BasketButton elem={{ id: 'salat-id', name: 'Салаты' }} {...itemProps} />
          </div>
        );
      case "Закуски":
        return (
          // <Snacks basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
            <Snacks {...itemProps} />
            <BasketButton elem={{ id: 'snacks-id', name: 'Закуски' }} {...itemProps} />
          </div>
        );
      case "Супы":
        return (
          // <Soups basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
            <Soups {...itemProps} />
            <BasketButton elem={{ id: 'soups-id', name: 'Супы' }} {...itemProps} />
          </div>
        );
      case "Гарниры":
        return (
          // <Garnish basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
            <Garnish {...itemProps} />
            <BasketButton elem={{ id: 'garnish-id', name: 'Гарниры' }} {...itemProps} />
          </div>
        );
      case "Выпечка":
        return (
          // <Bekery basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
            <Bakery {...itemProps} />
            <BasketButton elem={{ id: 'bakery-id', name: 'Выпечка' }} {...itemProps} />
          </div>
        );
      case "Горячие блюда":
        return (
          // <HotDishes
          //   basketItems={basketItems}
          //   setBasketItems={setBasketItems}
          // />
          <div>
          <HotDishes {...itemProps} />
            <BasketButton elem={{ id: 'hotDishes-id', name: 'Горячие блюда' }} {...itemProps} />
          </div>
        );
      case "Блюда на мангале":
        return (
          // <DishesOnTheGrill
          //   basketItems={basketItems}
          //   setBasketItems={setBasketItems}
          // />
          <div>
          <DishesOnTheGrill {...itemProps} />
            <BasketButton elem={{ id: 'dishesOnTheGrill-id', name: 'Блюда на мангале' }} {...itemProps} />
          </div>
        );
      case "Соусы":
        return (
          // <Sauces basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
          <Sauces {...itemProps} />
            <BasketButton elem={{ id: 'sauces-id', name: 'Соусы' }} {...itemProps} />
          </div>
        );
      case "Хлеб":
        return (
          // <Bread basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
          <Bread {...itemProps} />
            <BasketButton elem={{ id: 'Bread-id', name: 'Хлеб' }} {...itemProps} />
          </div>
        );
      case "Десерты":
        return (
          // <Dessert basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
          <Dessert {...itemProps} />
            <BasketButton elem={{ id: 'dessert-id', name: 'Десерты' }} {...itemProps} />
          </div>
        );
      case "Напитки":
        return (
          // <Beverage basketItems={basketItems} setBasketItems={setBasketItems} />
          <div>
          <Beverage {...itemProps} />
            <BasketButton elem={{ id: 'Beverage-id', name: 'Напитки' }} {...itemProps} />
          </div>
        );

      default:
        return "Такого блюда нет";
    }
  };

  return (
    <div className={styles.toggleNavBar} ref={modalRef}>
      <img
        onClick={handleCklick}
        src={Close}
        alt=""
        className={styles.CloseIcon}
      />
      {menuDrop && (
        <span onClick={backFromMenu}>
          <img className={styles.backIconToggle} src={BackIcon} alt="" />
        </span>
      )}
      {!menuDrop && (
        <div className={styles.NavItemBox}>
          {pages.map((elem, idx) => {
            if (elem.url === "/menu") {
              return (
                <>
                  <NavLink
                    to={elem.url}
                    className={({ isActive }) => (isActive ? "active" : "")}
                    key={idx}
                    onClick={() => {
                      menuDropControl();
                      handleClickScroll(elem.url);
                      // handleCklick()
                    }}
                  >
                    {elem.name}
                  </NavLink>
                </>
              );
            } else {
              return (
                <>
                  <NavLink
                    key={idx}
                    to={elem.url}
                    onClick={() => {
                      handleClickScroll(elem.url);
                      handleCklick();
                    }}
                  >
                    {elem.name}
                  </NavLink>
                </>
              );
            }
          })}
        </div>
      )}
      <div>
        {menuDrop && (
          <div className={styles.NavItemBox}>
            {Pages.map((item) => (
              <button
                onClick={() => setCardProducts(item.name)}
                key={item.name}
              >
                {item.name}
              </button>
            ))}
          </div>
        )}
        {renderSwitch(cardProducts)}
      </div>
    </div>
  );
}

export default HeaderModal;
