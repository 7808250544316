import b from "./Basket.module.scss";
import h from "./Header.module.scss";
import X from "./del.svg";
import axios from "axios";
import basketApi from "../../../src/api/basketItemsApi";
import { useEffect, useState } from "react";

export default function BasketTovar({
  setCountInfo,
  countInfo,
  removeBasket,
  setkarzinkaTovar,
  karzinkaTovar,
  basketItems,
  setBasketItems,
  setTotalCartPrice,
  ...info
}) {
  // const [count, setCount] = useState(info.count || 1);

  const [productCounts, setProductCounts] = useState(info.count);

  const tokenTwo = localStorage.getItem("token");
  useEffect(() => {

  }, [info]);

  const handler = {
    delete: async () => {
      await basketApi.delete(info.goods.id, tokenTwo);
      setBasketItems(basketItems.filter((item) => item !== info));
      window.location.reload();
    },
    increase: async () => {
      try {
        await basketApi.countPatch(info.goods.id, tokenTwo, info.count + 1);
        setProductCounts((prev) => Number(prev) + 1);
        const newBasket = basketItems.map((el) => {
          if (el.goods.id === info.goods.id) {
            return { ...el, count: el.count + 1 };
          }
          return el;
        });
        setBasketItems(newBasket);
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    },
    decrease: async () => {
      if (info.count > 1) {
        try {
          await basketApi.countPatch(info.goods.id, tokenTwo, info.count - 1);
          setProductCounts((prev) => Number(prev) - 1);
          const newBasket = basketItems.map((el) => {
            if (el.goods.id === info.goods.id) {
              return { ...el, count: el.count - 1 };
            }
            return el;
          });
          setBasketItems(newBasket);
          window.location.reload();
        } catch (err) {
          console.error(err);
        }
      } else {
        await handler.delete();
      }
    },
  }

  return (
    <div className={b.map__item}>
      <div className={b.map__item__flex}>
        {productCounts > 0 && info.goods.images && info.goods.images[0] && info.goods.images[0].images ? (
          <img
            src={`https://dali-khinkali.ru${info.goods.images[0].images}`}
            alt="img"
            className={h.nav__kar__item_info_img}
          />
        ) : (
          <p>No image available</p>
        )}

        <div className={b.map__item__info}>
          <p>{info.goods.title}</p>
          <p>{info.goods.price * info.count} руб.</p>
        </div>
      </div>

      <div className={h.nav__kar__item__fun}>
        <div
          className={h.nav__kar__item__fun__add}
          onClick={handler.decrease}
        >
          -
        </div>
        <h3>{productCounts}</h3>
        <div
          className={h.nav__kar__item__fun__add}
          onClick={handler.increase}
        >
          +
        </div>
        <img
          src={X}
          className={h.nav__danniy__header__exit}
          alt="exit"
          onClick={handler.delete}
        />
      </div>
    </div>
  );
}