import CardCarousel from "./img/card-menu.png";




const Images = [
  {
    id: 1,
    images: [`${CardCarousel}`],
    alt: "Image 1",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price: "720 руб.",
  },
  {
    id: 2,
    images: [`${CardCarousel}`],
    alt: "Image 2 ",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price: "720 руб.",
  },
  {
    id: 3,
    images: [`${CardCarousel}`],
    alt: "Image 3",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price: "720 руб.",
  },
  {
    id: 4,
    images: [`${CardCarousel}`],
    alt: "Image 4",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price:
      "720 руб.",
  },
  {
    id: 5,
    images: [`${CardCarousel}`],
    alt: "Image 5",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price:
      "720 руб.",
  },
  {
    id: 6,
    images: [`${CardCarousel}`],
    alt: "Image 6",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики ",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price: "720 руб.",
  },
  {
    id: 7,
    images: [`${CardCarousel}`],
    alt: "Image 7",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price:
      "720 руб.",
  },
  {
    id: 8,
    images: [`${CardCarousel}`],
    alt: "Image 8",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price:
      "720 руб.",
  },
  {
    id: 9,
    images: [`${CardCarousel}`],
    alt: "Image 9",
    title: "Салат айсберг, креветки, помидоры черри, сыр пармезан, соус цезарь, сухарики",
    subtitle: "Салат цезарь с креветками ",
    weight: "180г",
    price:
      "720 руб.",
  },
];

export default Images;
