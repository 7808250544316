import axios from "axios";

// export const banquetHullApi = async (token, data) => {
//   try {
//     const res = await axios.request({
//       url: "https://dali-khinkali.ru/api/send-banquet/",
//       data: JSON.stringify(data),
//       headers: {
//         Authorization: `Token ${token}`,
//         "Content-Type": "application/json",
//       },
//       method: "POST",
//     });
//     return res;
//   } catch (error) {
//     return {
//       status: "error",
//       message: error.message,
//     };
//   }
// };

export const banquetHullApi = async (token, data) => {
  try {
    const res = await axios.post("https://dali-khinkali.ru/api/send-banquet/", data, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    });

    // Проверка статуса ответа
    if (res.status === 200) {
      return {
        status: "success",
        data: res.data,
      };
    } else {
      return {
        status: "error",
        message: `Unexpected response status: ${res.status}`,
      };
    }
  } catch (error) {
    // Улучшенная обработка ошибок
    const errorMessage = error.response?.data?.error || error.message || "Unknown error occurred";
    return {
      status: "error",
      message: errorMessage,
    };
  }
};
