// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .basketButton_countBtn__Ak8Y0 {
    width: 45%;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    box-shadow: 0px 2px 2px 4px #f69049;
    gap: 10px;
  }

  .basketButton_formBtn__e69dM {
    display: flex;
    justify-content: center;
  }


  .basketButton_countDecrease__UlCjL,
  .basketButton_countIncrease__kmdL8 {
    padding: 5px 15px;
    font-family: "Gilroy";
    font-size: 18px;
    font-weight: 800;
    color: #000;
    background: #f69049;
    border-radius: 50%;
  }
  .basketButton_countBasket__QgfFm {
    color: #000;
  }


  
  .basketButton_countIncrease__kmdL8:hover {
    background: #000;
    color: #ffffff;
  }
  .basketButton_countDecrease__UlCjL:hover {
    background: #000;
    color: #ffffff;
  }
  .basketButton_countDecrease__UlCjL:active {
    cursor: grabbing;
  }
  .basketButton_countIncrease__kmdL8:active {
    cursor: grabbing;
  }`, "",{"version":3,"sources":["webpack://./src/components/basketButton/basketButton.module.css"],"names":[],"mappings":"EAAE;IACE,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,mCAA2B;YAA3B,2BAA2B;IAC3B,mCAAmC;IACnC,SAAS;EACX;;EAEA;IACE,aAAa;IACb,uBAAuB;EACzB;;;EAGA;;IAEE,iBAAiB;IACjB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,WAAW;EACb;;;;EAIA;IACE,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB","sourcesContent":["  .countBtn {\n    width: 45%;\n    min-height: 50px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50px;\n    backdrop-filter: blur(20px);\n    box-shadow: 0px 2px 2px 4px #f69049;\n    gap: 10px;\n  }\n\n  .formBtn {\n    display: flex;\n    justify-content: center;\n  }\n\n\n  .countDecrease,\n  .countIncrease {\n    padding: 5px 15px;\n    font-family: \"Gilroy\";\n    font-size: 18px;\n    font-weight: 800;\n    color: #000;\n    background: #f69049;\n    border-radius: 50%;\n  }\n  .countBasket {\n    color: #000;\n  }\n\n\n  \n  .countIncrease:hover {\n    background: #000;\n    color: #ffffff;\n  }\n  .countDecrease:hover {\n    background: #000;\n    color: #ffffff;\n  }\n  .countDecrease:active {\n    cursor: grabbing;\n  }\n  .countIncrease:active {\n    cursor: grabbing;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countBtn": `basketButton_countBtn__Ak8Y0`,
	"formBtn": `basketButton_formBtn__e69dM`,
	"countDecrease": `basketButton_countDecrease__UlCjL`,
	"countIncrease": `basketButton_countIncrease__kmdL8`,
	"countBasket": `basketButton_countBasket__QgfFm`
};
export default ___CSS_LOADER_EXPORT___;
