import React, { useEffect, useState, useRef } from "react";
import styles from "./CustomSlider.module.css";
import "../../assets/general-styles/styles.css";
import BasketButton from "../basketButton/basketButton";
import axios from "axios";

const Card = ({ elem, settings, Slider, setBasketItems, basketItems }) => {

  const scrollRef = useRef();
  const [add, setAdd] = useState(false);
  const token = localStorage.getItem("token");
  const isTokenValid = token && token.length > 5;
  const currentItem = basketItems.find(item => item.goods.id === elem.id);
  const currentCount = currentItem ? currentItem.count : 0;

  useEffect(() => {
    const updateCartOnServer = async () => {
      if (currentCount > 0) {
        try {
          await axios.patch(
            `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
            { count: currentCount },
            {
              headers: {
                authorization: `Token ${token}`,
              },
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    };

    updateCartOnServer();
  }, [currentCount, elem.id, token]);

  const addToCart = async () => {
    if (!isTokenValid) {
      alert("Пожалуйста зарегистрируйтесь!!");
      return; // Прерываем выполнение функции
    }
    const isInBasket = basketItems.some(item => item.goods.id === elem.id);
    try {
      await axios.post(
        `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
        { count: isInBasket ? currentCount + 1 : 1 },
        {
          headers: {
            authorization: `Token ${token}`,
          },
        }
      );
      setBasketItems(prev => {
        const existingItem = prev.find(item => item.goods.id === elem.id);

        if (existingItem) {
          // Если товар уже есть в корзине, увеличиваем его количество
          return prev.map(item =>
            item.goods.id === elem.id ? { ...item, count: item.count + 1 } : item
          );
        } else {
          // Если товара нет в корзине, добавляем его с количеством 1
          return [...prev, { goods: { ...elem, ...elem.all }, count: 1 }];
        }
      });
      // setAdd(true)
    } catch (err) {
      console.log(err);
    }
  };

  const decreaseItemCount = async () => {
    if (currentCount > 1) {
      // Уменьшаем количество товара в состоянии корзины
      setBasketItems(prev => prev.map(item =>
        item.goods.id === elem.id ? { ...item, count: item.count - 1 } : item
      ));

      // Обновляем базу данных, чтобы отразить уменьшение
      try {
        await axios.patch(
          `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
          { count: -1 }, // Уменьшаем количество на 1
          {
            headers: {
              authorization: `Token ${token}`,
            },
          }
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      // Удаляем товар из состояния корзины и базы данных
      setBasketItems(prev => prev.filter(item => item.goods.id !== elem.id));

      // Отправляем DELETE-запрос для удаления товара из базы данных
      try {
        await axios.delete(
          `https://dali-khinkali.ru/api/goods/${Number(elem.id)}/shopping_cart/`,
          {
            headers: {
              authorization: `Token ${token}`,
            },
          }
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const increaseItemCount = async () => {
    setBasketItems(prev => prev.map(item =>
      item.goods.id === elem.id ? { ...item, count: item.count + 1 } : item
    ));
  };

  useEffect(() => {
    const scrollPosition = scrollRef.current.scrollTop; // Сохраняем позицию прокрутки
    // Логика, которая может изменить состояние
    // Восстанавливаем позицию прокрутки
    scrollRef.current.scrollTop = scrollPosition;
  }, [basketItems]);

  // const handleSubmit = (event) => {
  //   event.preventDefault(); // предотвращает обновление страницы
  // };

  return (
    <Slider>
      <div ref={scrollRef} className={styles.CardCarousel} key={elem.id}>
        <img
          src={elem.src}
          alt={elem.title}
          className={styles.img}
          style={{ width: "100%", margin: " 0 auto" }}
        />
        <div className="title">{elem.title}</div>
        <div className={styles.BoxWeightSubTitle}>
          <div className="title">{elem.subtitle}</div>
          <span className="weight">{elem.weight} г.</span>
        </div>
        <div className="btns">
          <p className="price">{elem.price} руб.</p>
          {/* <BasketButton
            elem={elem}
            basketItems={basketItems}
            setBasketItems={setBasketItems}
          /> */}
          <form className={styles.formBtn} onSubmit={(event) => event.preventDefault()}>
            {
              currentCount ? (
                <div className={styles.countBtn} >
                  <button type="button" className={styles.countDecrease} onClick={() => decreaseItemCount()}>-</button>
                  <span className={styles.countBasket}>{currentCount}</span>
                  <button type="button" className={styles.countIncrease} onClick={() => increaseItemCount()}>+</button>
                </div >
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={() =>
                    isTokenValid
                      ? addToCart()
                      : alert("Пожалуйста зарегистрируйтесь!!")
                  }
                >
                  + В корзину
                </button>
              )}
          </form>
        </div>
      </div>
    </Slider >
  );
};

export default Card;
