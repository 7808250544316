import React from "react";
import styles from "./Vozvrat.module.css";
import { Container } from "@mui/material";
import Footer from "../footer";

export const Vozvrat = () => {
  return (
    <>
      <h1 className={styles.DelTitle}>Возврат</h1>
      <Container className={styles.containerVozvrat}
        maxWidth="lg"
        alignItems="center"
        sx={{ display: "flex", mt: 12, pl: 3, pr: 3 }}
        marginTop="-30px"
      >
        <div className={styles.FormVozvrat}>
          <form className={styles.FormVozvratformLeft}>
            <h3>Выберите дату заказа</h3>
            <p className={styles.FormVozvratInput}>
              <input type="date" />
            </p>
            <p className={styles.FormVozvratInput}>
              <input
                placeholder="Опишите с чем связан отказ от заказа"
                type="text"
              />
            </p>
            <p className={styles.FormVozvratInput}>
              <input
                placeholder="Напишите номер карты куда вернуть средства"
                type="text"
              />
            </p>
            <button className={styles.buttonVozvrat}>Отправить</button>
          </form>
          <div className={styles.FormVozvratformRight}>
            <p className={styles.FormVozvratformRight_p}>
              Процедура возврата товара регламентируется статьей 26.1
              федерального закона «О защите прав потребителей».
            </p>
            <ul className={styles.FormVozvratformRight_ul}>
              <li>
                Потребитель вправе отказаться от товара в любое время до его
                передачи, а после передачи товара - в течение семи дней;
              </li>
              <li>
                При отказе потребителя от товара продавец должен возвратить ему
                денежную сумму, уплаченную потребителем по договору, за
                исключением расходов продавца на доставку от потребителя
                возвращенного товара, не позднее чем через десять дней со дня
                предъявления потребителем соответствующего требования;
              </li>
              <li>
                Потребитель не вправе отказаться от товара надлежащего
                качества, имеющего индивидуально-определенные свойства, если
                указанный товар может быть использован исключительно
                приобретающим его человеком.
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.FormVozvratformRight}>
          <h1 className={styles.DelTitle}>Отказ от услуги</h1>

          <p className={styles.FormVozvratformRight_p}>
            Процедура возврата товара регламентируется статьей 26.1
            федерального закона «О защите прав потребителей».
          </p>
          <ul className={styles.FormVozvratformRight_ul}>
            <li>
              Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32 федерального закона «О защите прав потребителей»
            </li>
            <li>
              Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив исполнителю часть цены пропорционально части оказанной услуги до получения извещения о расторжении указанного договора и возместив исполнителю расходы, произведенные им до этого момента в целях исполнения договора, если они не входят в указанную часть цены услуги;
            </li>
            <li>
              Потребитель при обнаружении недостатков оказанной услуги вправе по своему выбору потребовать:
              <p className={styles.FormVozvratformRight_ul_p}> o	безвозмездного устранения недостатков;<br></br>
                o	соответствующего уменьшения цены;<br></br>
                o	возмещения понесенных им расходов по устранению недостатков своими силами или третьими лицами;
              </p>
            </li>

            <li>
              Потребитель вправе предъявлять требования, связанные с недостатками оказанной услуги, если они обнаружены в течение гарантийного срока, а при его отсутствии в разумный срок, в пределах двух лет со дня принятия оказанной услуги;
            </li>

            <li>
              Исполнитель отвечает за недостатки услуги, на которую не установлен гарантийный срок, если потребитель докажет, что они возникли до ее принятия им или по причинам, возникшим до этого момента;
            </li>
          </ul>
        </div>
      </Container>

      <Footer />
    </>
  );
};
